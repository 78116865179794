/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState} from "react";
import web3 from "web3";
import { Link , useLocation} from "react-router-dom";
import { useConnectWallet, useActiveWeb3React } from '../../hooks';
import {isMobile} from 'react-device-detect';
import { Drawer , notification } from 'antd';
import ConnectWallet from "../connect-wallet/ConnectWallet"
import './style.css'

const Header = () => {
    const { account } = useActiveWeb3React();
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const logoDrawer = (
        <>
            <div className="box-logo-dw">
                <img src="./images/logo-new.svg" alt="" />
            </div>
        </>
    )

    const openNotification = () => {
        notification.open({
            message: 'Coming soon',
            duration: 1,
        });
    };
    
    const location = useLocation();
    const pathSymbol = location.pathname.split("?");
    const pathUrl = pathSymbol[0]

    return (
        <>
            {isMobile ? 
                <>
                    <header className="main-header">
                        <div className="container">
                            <div className="wrap-header">
                                <div className="header-l">
                                    <div className="box-img">
                                        <img src="./images/logo-new.svg" alt="" />
                                    </div>
                                </div>
                                <div className="header-r">
                                    <button type="button" className="btn-open-menu" onClick={showDrawer}>
                                        <img src="./images/open-menu.png" alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </header>
                    <Drawer title={logoDrawer} className="drawer-menu" placement="right" onClose={onClose} open={open}>
                        <div className="main-menu">
                            <ul className="list-menu">
                                <Link to="/">
                                    <a href="#">Hold</a>
                                </Link>
                                <Link to="/earn" className="active">
                                    <a href="#">LP</a>
                                </Link>
                                <Link to="#">
                                    <a href="#">Rich</a>
                                </Link>
                                <Link to="/own">
                                    <a href="#">Own NFT</a>
                                </Link>
                                <Link to="#" onClick={openNotification}>
                                    <a href="#">Genesis Holder</a>
                                </Link>
                                <a href="https://hold-vip.gitbook.io/hold-land/" target="_blank">Docs</a>
                            </ul>
                        </div>
                    </Drawer>
                </>
                :
                <>
                    <header className="main-header">
                        <div className="container">
                            <div className="wrap-header">
                                <div className="header-l">
                                    <div className="box-img">
                                        <Link to="/">
                                            <img src="./images/logo-new.svg" alt="" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="header-r">
                                    <div className="main-menu">
                                        {pathUrl === "/lp" ?
                                            <>
                                                <ul className="list-menu">
                                                    <Link to="/">
                                                        <a href="#">Hold</a>
                                                    </Link>
                                                    <Link to="/lp" className="active">
                                                        <a href="#">LP</a>
                                                    </Link>
                                                    <Link to="/rich">
                                                        <a href="#">Rich</a>
                                                    </Link>
                                                    <Link to="/own">
                                                        <a href="#">Own NFT</a>
                                                    </Link>
                                                    <Link to="#" onClick={openNotification}>
                                                        <a href="#">Genesis Holder</a>
                                                    </Link>
                                                    <a href="https://hold-vip.gitbook.io/hold-land/" target="_blank">Docs</a>
                                                </ul>
                                            </>
                                            :
                                            <>
                                                {pathUrl === "/staking" ?
                                                    <>
                                                        <ul className="list-menu">
                                                            <Link to="/">
                                                                <a href="#">Hold</a>
                                                            </Link>
                                                            <Link to="/lp">
                                                                <a href="#">LP</a>
                                                            </Link>
                                                            <Link to="/rich">
                                                                <a href="#">Rich</a>
                                                            </Link>
                                                            <Link to="/own">
                                                                <a href="#">Own NFT</a>
                                                            </Link>
                                                            <Link to="#" onClick={openNotification}>
                                                                <a href="#">Genesis Holder</a>
                                                            </Link>
                                                            <a href="https://hold-vip.gitbook.io/hold-land/" target="_blank">Docs</a>
                                                        </ul>
                                                    </>
                                                    :
                                                    <>
                                                        {pathUrl === "/own" ?
                                                            <>
                                                                <ul className="list-menu">
                                                                    <Link to="/">
                                                                        <a href="#">Hold</a>
                                                                    </Link>
                                                                    <Link to="/lp">
                                                                        <a href="#">LP</a>
                                                                    </Link>
                                                                    <Link to="/rich">
                                                                        <a href="#">Rich</a>
                                                                    </Link>
                                                                    <Link to="/own" className="active">
                                                                        <a href="#">Own NFT</a>
                                                                    </Link>
                                                                    <Link to="#" onClick={openNotification}>
                                                                        <a href="#">Genesis Holder</a>
                                                                    </Link>
                                                                    <a href="https://hold-vip.gitbook.io/hold-land/" target="_blank">Docs</a>
                                                                </ul>
                                                            </>
                                                            :
                                                            <>
                                                                {pathUrl === "/rich" ?
                                                                    <>
                                                                        <ul className="list-menu">
                                                                            <Link to="/">
                                                                                <a href="#">Hold</a>
                                                                            </Link>
                                                                            <Link to="/lp">
                                                                                <a href="#">LP</a>
                                                                            </Link>
                                                                            <Link to="/rich" className="active">
                                                                                <a href="#">Rich</a>
                                                                            </Link>
                                                                            <Link to="/own">
                                                                                <a href="#">Own NFT</a>
                                                                            </Link>
                                                                            <Link to="#" onClick={openNotification}>
                                                                                <a href="#">Genesis Holder</a>
                                                                            </Link>
                                                                            <a href="https://hold-vip.gitbook.io/hold-land/" target="_blank">Docs</a>
                                                                        </ul>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <ul className="list-menu">
                                                                            <Link to="/" className="active">
                                                                                <a href="#">Hold</a>
                                                                            </Link>
                                                                            <Link to="/lp">
                                                                                <a href="#">LP</a>
                                                                            </Link>
                                                                            <Link to="/rich">
                                                                                <a href="#">Rich</a>
                                                                            </Link>
                                                                            <Link to="/own">
                                                                                <a href="#">Own NFT</a>
                                                                            </Link>
                                                                            <Link to="#" onClick={openNotification}>
                                                                                <a href="#">Genesis Holder</a>
                                                                            </Link>
                                                                            <a href="https://hold-vip.gitbook.io/hold-land/" target="_blank">Docs</a>
                                                                        </ul>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="button-gr">
                                        <button type="button" className="btn-buynow" onClick={() => window.open("https://app.uniswap.org/#/swap?outputCurrency=0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5")}>
                                            Buy Now
                                        </button>
                                    </div>
                                    <div className="button-gr">
                                        <ConnectWallet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </>
            }
        </>
    )
}
export default Header