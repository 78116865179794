import React, { useEffect , useState} from "react";
import { Modal , Input , message , Spin} from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { useContract} from "../../hooks/useContract";
import abiNft from "../abi/abiNft.json"
import stakeNftLpAbi from "../abi/stakeNftLpAbi.json"
import ConnectWallet from "../../component/connect-wallet/ConnectWallet";
import {_stake , _approveToken , _widthrawUnstake, _refreshLiquidity} from "../utils"

const ContentStaking = (props:any) => {
    const { liquidity , priceHold , priceEth , ethBalance , holdBalance} = props;
    const { account } = useActiveWeb3React();
    const [isLoading, setIsLoading] = useState(false);
    const [isApprove, setApprove] = useState(null);
    const [amountHold, setAmountHold] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const scStake = "0xb662CB95bb91337c497F8bf5c392D1b5a433fFF2"
    const scStakeContract: any = useContract(scStake, stakeNftLpAbi);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [isModalOpenUn, setIsModalOpenUn] = useState(false);

    const showModalUn = () => {
        setIsModalOpenUn(true);
    };

    const handleOkUn = () => {
        setIsModalOpenUn(false);
    };

    const handleCancelUn = () => {
        setIsModalOpenUn(false);
    };

    const titleStaking = (
        <>
            <div className="title-staking">
                Stake <span>HOLD-ETH LP Tokens</span>
            </div>
        </>
    )

    const titleUnStaking = (
        <>
            <div className="title-staking">
                UnStake <span>HOLD-ETH LP Tokens</span>
            </div>
        </>
    )

    const [balanceNftId, seBbalanceNftId] = useState(0);
    const [nftPoolId, setNftPoolId] = useState<any>(0);

    const [amountLiqui, setAmountLiqui] = useState(0);
    const nftContract: any = useContract("0xc36442b4a4522e871399cd717abdd847ab11fe88", abiNft);
    const [lastBlockTime , setLastBlockTime] = useState<any>(0)
    const valueHold = (holdBalance * (amountLiqui / liquidity)) * priceHold
    const valueEth = (ethBalance * (amountLiqui / liquidity)) * priceEth

    const getBlanceNft = async () => {
        if (nftContract) {
            nftContract.tokenOfOwnerByIndex(account , 0).then((res:any) => {
            seBbalanceNftId(res.toString());
          });
        }
    };

    useEffect(() => {
        getBlanceNft()
    }, [nftContract]);

    useEffect(() => {
        if (scStakeContract && account ) {
            scStakeContract.userInfo(account).then((res: any) => {
                let amountRes = res[1].toString()
                let amountPool = res[0].toString() / 1e18
                let lastBlock = res.lastStakingBlock.toString()
                setNftPoolId(amountRes)
                setAmountLiqui(amountPool)
                setLastBlockTime(lastBlock)
            })
        }
    }, [ account , scStakeContract]);

    let blockCoutdown = 7115
    let blockEnd = Number(lastBlockTime) + Number(blockCoutdown)

    const handleApprove = async () => {
        setIsLoading(true);
        if (balanceNftId > 0) {
            await _approveToken(nftContract ,scStake , balanceNftId)
            .then((res:any) => {
                res.wait().then((res1: any) => {
                if (res1 !== null) {
                    message.success("Approved successfully!!! ");
                    setIsLoading(false);
                    setApprove(isApprove)
                    checkIsApprove();
                } else {
                    setIsLoading(false);
                }
                });
            })
                .catch((error:any) => {
                setIsLoading(false);
            });
        } else {
            message.warning(" Not balance NFT ");
        }
    };

    const checkIsApprove = async () => {
        if (nftContract && account && balanceNftId > 0 ) {
            nftContract.getApproved(balanceNftId).then((res: any) => {
                setApprove(res)
            }).catch((error:any) => {
                setApprove(null)
            });
        } else {
            setApprove(null)
        }
    };

    useEffect(() => {
        checkIsApprove()
    }, [ account , balanceNftId , nftContract]);


    const handleStake = async () => {
        console.log("scStakeContract",scStakeContract)
        console.log("balanceNftId",balanceNftId)
        setIsLoading(true);
        try {
          await _stake(scStakeContract, balanceNftId).then((res) => {
            if (res.hash) {
              setTimeout(() => {
                message.success("Stake successfully!!! ");
                setIsLoading(false);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }, 20000);
            } else {
              setIsLoading(false);
              message.warning(res.error.data.message);
            }
          });
        } catch (error) {
          setIsLoading(false);
        }
    };

    const handleUnstake = async () => {
        setIsLoading(true);
        try {
          await _widthrawUnstake(scStakeContract , true).then((res:any) => {
            if (res.hash) {
                setTimeout(() => {
                    message.success("UnStake successfully!!! ");
                    setIsLoading(false);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }, 20000);
            } else {
              setIsLoading(false);
              message.error(res.error.data.message);
            }
          });
        } catch (error:any) {
            console.log("error" , error)
            setIsLoading(false);
        }
    };
    const handleRefreshLiquidity = async () => {

        await _refreshLiquidity(scStakeContract)
            .then((res:any) => {
                res.wait().then((res1: any) => {
                if (res1 !== null) {
                    message.success("Refresh liquidity amount successfully!!! ");
                } else {
                    message.error("Refresh liquidity amount failed!!! ");
                }
                });
            })
            .catch((error:any) => {
                if (error) {
                    if (error.code === 4001 && error.message) {
                        message.error(error.message);
                    } else if (error.reason) {
                        message.error(error.reason);
                        
                    } else {
                        if (error.data && error.data.message) {
                            message.error(error.data.message);
                        }
                    }
                }
            }
        );
    };

    return (
        <>
            <div className="guide-row">
                <div className="title">
                    My Staking
                </div>
                <div className="box-img">
                    <img src="./images/hold-earn.svg" alt="" />
                    <img src="./images/eth-earn.svg" alt="" />
                </div>
                <div className="reward-row">
                    <div className="txt-liqui">
                        Liquidity amount <img src="./images/refresh-circle.svg" alt="" onClick={handleRefreshLiquidity}/>
                    </div>
                    {account ? 
                        <>
                            ${(valueHold + valueEth).toFixed(2)}
                        </>
                        :
                        <>
                            N/A
                        </>
                    }
                </div>
                {account ?
                    <>
                        <div className="button-row">
                            {nftPoolId > 0 ?
                                <>
                                    <button className="btn-unstake" type="button" onClick={showModalUn}>
                                        Unstake
                                    </button>
                                </>
                                :
                                <>
                                    <button className="btn-unstake dis" type="button">
                                        Unstake
                                    </button>
                                </>
                            }
                            {isApprove === null || isApprove === "0x0000000000000000000000000000000000000000" ?
                                <>
                                    {balanceNftId > 0 ?
                                        <>
                                            {isLoading ?
                                                <>
                                                    <button className="btn-stake dis" type="button">
                                                        Approve <Spin />
                                                    </button> 
                                                </>
                                                :
                                                <>
                                                    <button className="btn-stake" type="button" onClick={handleApprove}>
                                                        Approve
                                                    </button> 
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            <button className="btn-stake" type="button" onClick={() => window.open(`https://app.uniswap.org/#/pools/${nftPoolId}`)}>
                                                Update Liquidity 
                                            </button> 
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {nftPoolId > 0 ? 
                                        <>
                                            <button className="btn-stake" type="button" onClick={() => window.open(`https://app.uniswap.org/#/pools/${nftPoolId}`)}>
                                                Update Liquidity 
                                            </button> 
                                        </>
                                        :
                                        <>
                                            <button className="btn-stake" type="button" onClick={showModal}>
                                                Stake
                                            </button>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className="gr-connect-earn">
                            <ConnectWallet />
                        </div>
                    </>
                }
                <div className="days-row">
                    {account && nftPoolId !== "0" ?
                        <>
                            <div className="link" onClick={() => window.open(`https://etherscan.io/block/countdown/${blockEnd}`)} >
                                Unstake in: <span>{blockEnd} Block <img src="./images/lp.svg" alt="" /></span>
                            </div>
                        </>
                        :
                        <>
                            Unstake in: <span>N/A Block</span>
                        </>
                    }

                </div>
                <div className="get-lp">
                    <a target="_blank" href="https://app.uniswap.org/#/add/ETH/0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5/3000">Get HOLD-ETH LP <img src="./images/lp.svg" alt="" /></a>
                </div>
                <div className="get-lp">
                    <a target="_blank" href="https://support.uniswap.org/hc/en-us/articles/7423194619661-How-to-provide-liquidity-on-Uniswap-V3">How to add LP on Uniswap <img src="./images/lp.svg" alt="" /></a>
                </div>
            </div>
            <Modal className="modal-staking" title={titleStaking} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="content-modal">
                    <div className="get-lp-modal">
                        <div className="txt">
                            <a target="_blank" href="https://app.uniswap.org/#/add/ETH/0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5/3000">Get HOLD-ETH LP <img src="./images/lp.svg" alt="" /></a>
                        </div>
                    </div>
                    <div className="input-modal">
                        <Input value={balanceNftId} />
                    </div>
                </div>
                <div className="button-confirm">
                    <button className="btn-cancel" type="button" onClick={handleCancel}>
                        Cancel
                    </button>
                    {isLoading ?
                        <>
                            <button className="btn-confirm" type="button">
                                Confirm <Spin />
                            </button> 
                        </>
                        :
                        <>
                            <button className="btn-confirm" type="button" onClick={handleStake}>
                                Confirm
                            </button>
                        </>
                    }
                </div>
            </Modal>
            <Modal className="modal-staking" title={titleUnStaking} open={isModalOpenUn} onOk={handleOkUn} onCancel={handleCancelUn}>
                <div className="content-modal">
                    <div className="get-lp-modal">
                        <div className="txt">
                            Get HOLD-ETH LP <img src="./images/lp.svg" alt="" />
                        </div>
                    </div>
                    <div className="input-modal">
                        <Input value={nftPoolId} />
                    </div>
                </div>
                <div className="button-confirm">
                    <button className="btn-cancel" type="button" onClick={handleCancelUn}>
                        Cancel
                    </button>
                    {isLoading ?
                        <>
                            <button className="btn-confirm dis" type="button">
                                Confirm <Spin />
                            </button>
                        </>
                        :
                        <>
                            <button className="btn-confirm" type="button" onClick={handleUnstake}>
                                Confirm
                            </button>
                        </>
                    }
                </div>
            </Modal>
        </>
    )
}
export default ContentStaking