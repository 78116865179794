/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState} from "react";
import { Select , message , Spin } from 'antd';
import { _claimTokensNew , _userClaim , _totalClaimed} from "../utils";
import {isMobile} from 'react-device-detect';
import ConnectWallet from "../../component/connect-wallet/ConnectWallet";
import { useActiveWeb3React } from '../../hooks';
import { useContract } from "../../hooks/useContract";
import AirDrop from "../abi/AirDrop.json"
import {postData} from "../Store"
import moment from "moment";

interface TokenProps {
    address: string;
    symbol: string;
    decimals: number;
}
const Rich = () => {
    const { account } = useActiveWeb3React();
    const [dataAir , setDataAir] = useState<any>();
    const [dataStart , setDataStart] = useState<any>();
    const [dataEnd , setDataEnd] = useState<any>();
    const claimContractMeme = useContract(dataAir?.claimSC, AirDrop);
    const [isLoading, setIsLoading] = useState(false);
    const [isClaimed, setIsClaimed] = useState(false);
    const [totalClaimed, setTotalClaimed] = useState<any>(0);
    const [dataMessage , setDataMessage] = useState<any>();

    useEffect(() => {
        if (account) {
            let data:any = {
                "ownerAddress": account
            }
            postData('https://api.hold.vip/api/meme/airdrop',data).then((res)=>{
                setDataAir(res.data)
                setDataStart(res)
                setDataEnd(res)
                setDataMessage(res.message)
            })
        } else {
            let data:any = {
                "ownerAddress": "sss"
            }
            postData('https://api.hold.vip/api/meme/airdrop',data).then((res)=>{
                setDataAir(res.data)
                setDataStart(res)
                setDataEnd(res)
                setDataMessage(res.message)
            })
        }
	}, [account]);

    const addTokenToWallet = async (props: TokenProps) => {
        const { address, symbol, decimals } = props;
        const provider: any = (window as Window).ethereum
        try {
            const wasAdded = await provider.request({
                method: "wallet_watchAsset",
                params: {
                type: "ERC20",
                options: {
                    address,
                    symbol,
                    decimals,
                },
                },
            });
            if (!wasAdded) {
                message.success({
                type: "success",
                content: "Something went wrong.",
                className: "custom-class",
                duration: 2,
                });
            }
        } catch (error: any) {
            message.error({
                type: "error",
                content: error?.message,
                className: "custom-class",
                duration: 2,
            });
        }
    };

    let dateStart = dataStart?.startDate
    let dateEnd = dataEnd?.endDate
    let newDateStart =  moment.utc(dateStart).format('LLL');
    let newDateEnd =  moment.utc(dateEnd).format('LLL');

    const handleClaim = async () => {
        setIsLoading(true);
        try {
            await _claimTokensNew(claimContractMeme, dataAir?.token , dataAir?.keyId , dataAir?.sign , 18).then (
                (res) => {
                  res.wait().then((res1: any) => {
                    if (res1 !== null) {
                      setIsLoading(false);
                      setIsClaimed(true);
                    } else {
                      setIsLoading(false);
                      setIsClaimed(false)
                    }
                  });
                }
            )
        } catch (error: any) {
            setIsLoading(false);
            if (error?.error?.data) {
                message.error({
                    type: "error",
                    content: error?.error?.data?.message,
                    className: "custom-class",
                    duration: 2,
                });
            } else {
                if (error?.code === -32603) {
                    message.error("Cannot set properties of undefined")
                } else message.error({
                    type: "error",
                    content: error?.code,
                    className: "custom-class",
                    duration: 2,
                });
            }
        }
    };

    useEffect(() => {
        if (claimContractMeme && dataAir?.keyId > 0 ) {
            _userClaim(claimContractMeme, account, dataAir?.keyId).then((res:any) => {
                setIsClaimed(res);
            });
        }
    }, [account, claimContractMeme , dataAir?.keyId]);


    return (
        <>
             <div className="main-wrap-section res cus">
                <div className="container">
                    <div className="content-rich">
                        <div className="box-row-rich">
                            <div className="columns">
                                <div className="colum w-5">
                                    <div className="guide-rich-l">
                                        <div className="box-img">
                                            <img src="./images/rich-svg.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-7">
                                    <div className="guide-rich-r">
                                        <div className="title">
                                            <span className="main-color">RICH</span> Airdrop
                                        </div>
                                        <div className="address-wallet-banner">
                                            {isMobile ? 
                                                <>
                                                    0x39...4279
                                                </>
                                                :
                                                <>
                                                    0x39eC0A79b259b6d72B1D84cCD725Cd59b9d14279
                                                </>
                                            }
                                            <button className="add-token" onClick={() =>
                                                addTokenToWallet({
                                                    address: "0x39eC0A79b259b6d72B1D84cCD725Cd59b9d14279",
                                                    symbol: "RICH",
                                                    decimals: 18,
                                                })
                                            }>
                                                <img src="./images/meta-mask.svg" alt="" />
                                            </button>
                                        </div>
                                        <div className="item">
                                            <div className="txt-l">
                                                To be claimed: 
                                            </div>
                                            <div className="txt-r">
                                                {dataAir?.token} Rich <img src="./images/rich-icon.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="txt-l">
                                                Claims Start Time:
                                            </div>
                                            <div className="txt-r">
                                                {newDateStart}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="txt-l">
                                                Claims Expired Time:
                                            </div>
                                            <div className="txt-r">
                                                {newDateEnd}
                                            </div>
                                        </div>
                                        <div className="button-round">
                                            {account ? 
                                                <>
                                                    {isLoading ?
                                                        <>
                                                            <button type="button" className="btn-claim dis">
                                                                Claim <Spin />
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            {dataMessage !== null ?
                                                                <>
                                                                    <button type="button" className="btn-claim dis">
                                                                        {dataMessage}
                                                                    </button>
                                                                </>
                                                                :
                                                                <>
                                                                   {isClaimed ?
                                                                        <>
                                                                            <button type="button" className="btn-claim dis">
                                                                                Claimed
                                                                            </button>
                                                                        </>
                                                                        :
                                                                        <>
                                                                             {dataAir?.status === 1 ? 
                                                                                <>
                                                                                    <button type="button" className="btn-claim" onClick={handleClaim}>
                                                                                        Claim
                                                                                    </button>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <button type="button" className="btn-claim dis">
                                                                                        Claim
                                                                                    </button>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="title-connect">
                                                        Please Unlock Wallet.
                                                    </div>
                                                    <ConnectWallet />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Rich