/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState} from "react";
import { Link } from "react-router-dom";
import { Drawer , notification } from 'antd';
import './style.css'

const Footer = () => {
    const openNotification = () => {
        notification.open({
            message: 'Coming soon',
            duration: 1,
        });
    };
    
    return (
        <>
            <footer className="main-footer">
                <div className="container">
                    <div className="wrap-footer">
                        <div className="footer-left">
                            <div className="box-img">
                                <img src="./images/ft-new.svg" alt="" />
                            </div>
                        </div>
                        <div className="footer-center">
                            <ul className="list-menu-ft">
                                <Link to="/">
                                    <a href="#">Hold</a>
                                </Link>
                                <Link to="/lp">
                                    <a href="#">LP</a>
                                </Link>
                                <Link to="/rich">
                                    <a href="#">Rich</a>
                                </Link>
                                <Link to="/own">
                                    <a href="#">Own NFT</a>
                                </Link>
                                <Link to="#" onClick={openNotification}>
                                    <a href="#">Genesis Holder</a>
                                </Link>
                                <a href="https://hold-vip.gitbook.io/hold-land/" target="_blank">Docs</a>
                            </ul>
                        </div>
                        <div className="footer-mail">
                            <div className="mail-to">
                            <img src="./images/mail-ft.svg" alt="" />
                                <a href="mailto:holdvip@hold.vip">holdvip@hold.vip</a>
                            </div>
                        </div>
                        <div className="footer-right">
                            <div className="social-ft">
                                <a href="https://twitter.com/holdvip" target="_blank">
                                    <img src="./images/twi-hd.png" alt="" />
                                </a>
                                <a href="https://t.me/holdvip" target="_blank">
                                    <img src="./images/tele-hd.png" alt="" />
                                </a>
                                <a href="https://hold-vip.gitbook.io/hold-land/"target="_blank">
                                    <img src="./images/doc-hd.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer