import web3 from "web3";
import BigNumber from "bignumber.js";
import { MaxUint256 } from '@ethersproject/constants'

const convertNumber = (value) => {
  return web3.utils.toHex(web3.utils.toWei(value, "ether"));
};
export const convertTokenToWei = (value, decimal) => {
    let amount = web3.utils.toWei(value.toString(), 'ether')
    if (decimal !== 18)
      amount = new BigNumber(value).times(new BigNumber(10).pow(decimal)).toString()
    return amount
  }


export const _getRemainingRewards = async (claimContract) => {
  const res = claimContract && await claimContract.getRemainingRewards();
  return res;
};
export const _getIssuedRewards = async (claimContract) => {
  const res = claimContract && await claimContract.issuedRewards();
  return res;
};
export const _getTotalStakedPoolAmount = async (claimContract) => {
  const res = claimContract && await claimContract.totalStakedPoolAmount();
  return res;
};
export const _getLiquidity = async (scRouterUniswapContract) => {
  const res = scRouterUniswapContract && await scRouterUniswapContract.liquidity();
  return res;
};

export const _getLiquidityStakeNFTLp = async (scStakeNftLpContract, nftId) => {
  const res = scStakeNftLpContract && await scStakeNftLpContract.totalStakedPoolAmount();
  return res;
};
