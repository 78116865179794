import BigNumber from 'bignumber.js';
/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */

const BIG_TEN = new BigNumber(10)
const BSC_BLOCK_TIME = 3;
const BLOCKS_PER_YEAR = new BigNumber(
  (60 / BSC_BLOCK_TIME) * 60 * 24 * 365
); // 10512000

export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
  decimalTokenEarning: number
): number | any => {

  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(new BigNumber(tokenPerBlock).div(BIG_TEN.pow(decimalTokenEarning))).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)

  // console.log('==============');
  // console.log('stakingTokenPrice', stakingTokenPrice);
  // console.log('rewardTokenPrice', rewardTokenPrice);
  // console.log('totalStaked', totalStaked);
  // console.log('tokenPerBlock', tokenPerBlock);
  // console.log('totalRewardPricePerYear', totalRewardPricePerYear.toNumber());
  // console.log('totalStakingTokenInPool', totalStakingTokenInPool.toNumber());
  // console.log('==============');

  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

export const formatNumberAmount = (number:any, minPrecision = 0, maxPrecision = 2, locale = 'en-US') => {
    const options = {
      minimumFractionDigits: minPrecision,
      maximumFractionDigits: maxPrecision,
    }
    return Number(number).toLocaleString()
}