/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from 'bignumber.js';
import React, { useEffect , useState} from "react";
import { Modal , Input} from 'antd';
import switchNetwork from "../../utils/wallet";
import { MAPPING_CHAINID } from "../../constants";
import { useActiveWeb3React } from '../../hooks';
import { useContract} from "../../hooks/useContract";
import stakeNftLpAbi from "../abi/stakeNftLpAbi.json"
import RouterUniswap from "../abi/RouterUniswap.json"
import { _getRemainingRewards, _getIssuedRewards, _getTotalStakedPoolAmount, _getLiquidity, _getLiquidityStakeNFTLp } from "../utils-stake"
import { ethers } from "ethers";
import ContentStaking from "./ContentStaking";
import ContentReward from "./ContentReward";
import AbiContract from "../abi/AbiContract.json"
import abiNft from "../abi/abiNft.json"
import axios from 'axios';
import { formatNumberAmount, getPoolApr } from '../apr';
// import { getPoolApr } from "../apr";

declare const window: Window & typeof globalThis & { ethereum: any };
const Earn = () => {
    const { account } = useActiveWeb3React();

    const scStake = "0xb662CB95bb91337c497F8bf5c392D1b5a433fFF2"
    const tokenHoldAddress = "0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5"
    const tokenEthAddress = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"

    const scRouterUniswap = "0x299b378d1FeeAC8FEC756f041AB1117F7D6c8Df5"

    const nftAddress = "0xc36442b4a4522e871399cd717abdd847ab11fe88"

    const scStakeContract: any = useContract(scStake, stakeNftLpAbi);
    const scRouterUniswapContract: any = useContract(scRouterUniswap, RouterUniswap);

    
    const [tvl, setTVL] = useState<any>(0);


    // const [apy, setAPY] = useState<any>(0);
    const [issuedRewards, setIssuedRewards] = useState<any>(0);
    const [totalStakedPoolAmount, setTotalStakedPoolAmount] = useState<any>(0);
    const [pendingRewards, setPendingRewards] = useState<any>(0);
    const [liquidity, setLiquidity] = useState<any>(0);
    const [liquidityStakeNFTLp, setLiquidityStakeNFTLp] = useState<any>(0);
    const [priceHold, setPriceHold] = useState<any>(0);
    const [priceEth, setPriceEth] = useState<any>(0);
    const [priceRich, setPriceRich] = useState<any>(0);
    const [holdBalance, setHoldBalance] = useState<any>(0);
    const [ethBalance, setEthBalance] = useState<any>(0);
    // const [nftId, setNftId] = useState(0);
    const [nftPoolId, setNftPoolId] = useState(0);
    // const [poolApr, setPoolApr] = useState<any>(0);

    const nftContract: any = useContract(nftAddress, abiNft);

    const getPriceHold = async () => {
        let tokenId = 'hold-vip';
		const res = await axios.get(
            // 'https://api.coingecko.com/api/v3/simple/price?ids=hold-vip&vs_currencies=usd'
            'https://api.cryptorank.io/v1/currencies?api_key=bb2d9746b1b42f0e7bc3ee5139a78664658b446d5961f6702b2641b24d87&symbols=HOLD'
		);
        
		if (res && res.status === 200) {
			const data = res?.data?.data[0]?.values?.USD?.price.toFixed(13)
         
			setPriceHold(Number(data));
		}else{
            setPriceHold(0);
        }
	};
    const getPriceRich = async () => {
        
		// const res = await axios.get(
        //     'https://api.cryptorank.io/v1/currencies?api_key=bb2d9746b1b42f0e7bc3ee5139a78664658b446d5961f6702b2641b24d87&symbols=RICH'
		// );
		// if (res && res.status === 200) {
		// 	const data = res?.data?.data[0]?.values?.USD?.price.toFixed(13)
         
		// 	setPriceRich(Number(data));
		// }else{
        //     setPriceRich(0);
        // }
	};
    const getPriceEth = async () => {
		const res = await axios.get(
            'https://api.cryptorank.io/v1/currencies?api_key=bb2d9746b1b42f0e7bc3ee5139a78664658b446d5961f6702b2641b24d87&symbols=ETH'
		);
        
		if (res && res.status === 200) {
			const data = res?.data?.data[0]?.values?.USD?.price.toFixed(3);
			setPriceEth(Number(data));
		}else{
            setPriceEth(0);
        }
	};

    const balanceHoldContract: any = useContract(tokenHoldAddress, AbiContract);
    const balanceEthContract: any = useContract(tokenEthAddress, AbiContract);

    const getBlanceHoldPool = async () => {
        const amount = await balanceHoldContract.balanceOf(scRouterUniswap);
        setHoldBalance(amount.toString() / 1e18);
    };
    const getBlanceEthPool = async () => {
        const amount = await balanceEthContract.balanceOf(scRouterUniswap);
        setEthBalance(amount.toString() / 1e18);
    };

    const getRemainingRewards = async () => {
        if(scStakeContract && account){
            let remainingRewards = await _getRemainingRewards(scStakeContract);
            let number = Number(ethers.utils.formatUnits(remainingRewards, 18)).toFixed(1);
           
            setPendingRewards(number);
        }
    };

    const getLiquidity = async () => {
        if(scRouterUniswapContract && account){
            let liquidity = await _getLiquidity(scRouterUniswapContract);
            setLiquidity(Number(ethers.utils.formatUnits(liquidity, 18)));
        }
    };
    const getLiquidityStakeNFTLp = async (nftPoolId:any) => {
        if(scStakeContract && account && nftPoolId > 0){
            let liquidity = await _getLiquidityStakeNFTLp(scStakeContract, nftPoolId);
            setLiquidityStakeNFTLp(Number(ethers.utils.formatUnits(liquidity, 18)));
        }
    };

    const getIssuedRewards = async () => {
        if(scStakeContract && account){
            let issuedRewards = await _getIssuedRewards(scStakeContract);
            let number = Number(ethers.utils.formatUnits(issuedRewards, 18)).toFixed(1);
            setIssuedRewards(number);
        }
    };
    const getTotalStakedPoolAmount = async () => {
        if(scStakeContract && account){
            let totalStaked = await _getTotalStakedPoolAmount(scStakeContract);
            let number:any = Number(ethers.utils.formatUnits(totalStaked, 18));
            setTotalStakedPoolAmount(number);
        }
    };

    // const getNftId = async () => {
    //     if (nftContract) {
    //       await nftContract.tokenOfOwnerByIndex(account , 0).then((rs:any)=>{
    //         setNftId(rs.toString());
    //       }).catch((e:any)=>{
    //         console.log('getNftId', e);
    //       });
          
    //     }
    // };

    useEffect(() => {
        if (account && scStakeContract) {
            getRemainingRewards();
            getIssuedRewards();
            getTotalStakedPoolAmount();
        }
        
    }, [account, scStakeContract]);

    

    // useEffect(() => {
    //     if(account && nftContract){
    //         getNftId()
    //     }
    // }, [account, nftContract]);

    useEffect(() => {
        if (account && scRouterUniswapContract) {
            getLiquidity();
        }
        
    }, [account, scRouterUniswapContract]);

    useEffect(() => {
       
        if (account && scStakeContract && nftPoolId > 0) {
            getLiquidityStakeNFTLp(nftPoolId);
        }
        
    }, [account, nftPoolId, scStakeContract]);

    useEffect(() => {
        if (account) {
            getPriceHold();
            getPriceEth();
            getPriceRich();
        }
    }, [account]);

    useEffect(() => {
        if (account && balanceHoldContract && balanceEthContract) {
            getBlanceHoldPool();
            getBlanceEthPool();
        }
    }, [account, balanceHoldContract, balanceEthContract]);

    useEffect(() => {
        if (scStakeContract && account ) {
            scStakeContract.userInfo(account).then((res: any) => {
                let amountRes = res[1].toString();
                setNftPoolId(amountRes)
            }).catch((e:any)=>{});
        }
    }, [ account , scStakeContract]);

    let amountEth:any = 0;
    let amountHold:any = 0;

    if (account && ethBalance > 0 && liquidity > 0 && totalStakedPoolAmount > 0) {
         amountEth = ethBalance * totalStakedPoolAmount/liquidity;
    }
    if (account && holdBalance > 0 && liquidity > 0 && totalStakedPoolAmount > 0) {
        amountHold = holdBalance * totalStakedPoolAmount/liquidity;
    }

    let amountEthPrice:any = priceEth > 0 ? amountEth * priceEth : 0;
    let amountHoldPrice:any = priceHold > 0 ? amountHold * priceHold : 0; 

    // console.log('----------------------');
    // console.log('amountEth', amountEth);
    // console.log('priceEth', priceEth);
    // console.log('total eth', amountEth * priceEth);

    // console.log('amountHold', amountHold);
    // console.log('priceHold', priceHold);
    // console.log('total hold', amountHold * priceHold);

    // console.log('----------------------');
    // console.log('ethBalance', ethBalance);
    // console.log('holdBalance', holdBalance);
    // console.log('priceEth', priceEth);
    // console.log('priceHold', priceHold);
    // console.log('liquidityStakeNFTLp', liquidityStakeNFTLp);
    // console.log('liquidity', liquidity);
    // console.log('scRouterUniswap', scRouterUniswap);
    // console.log('scStake', scStake);

    let totalReward:any = 20500000000000000;
    let distributionDuration:any = 45;
    let dailyEmission:any = totalReward/distributionDuration;
    let tokenPerBlock:any = dailyEmission/7155;

    let poolApr:any = 0;
    if(priceHold > 0 && totalStakedPoolAmount){
        
        poolApr = getPoolApr(
            priceHold,
            0.0000000000002677,
            totalStakedPoolAmount,
            tokenPerBlock,
            18,
        )
        // console.log('poolApr', poolApr);
    }
        

    

    const _chainId = MAPPING_CHAINID["arb"];
    const [chain , setChain] = useState()

    useEffect(() => {
        window.ethereum?.request({ method: "eth_chainId" }).then((res :any) => {           
            if (res !== _chainId) {
                switchNetwork(_chainId);
            }
            setChain(res)
        });
    }, [chain]);

    

    // const BSC_BLOCK_TIME = 3;

    // const BLOCKS_PER_YEAR = new BigNumber(
    // (60 / BSC_BLOCK_TIME) * 60 * 24 * 365
    // ); // 10512000

    // const totalRewardPricePerYear = new BigNumber(priceEth).times(new BigNumber(tokenPerBlock).div(BIG_TEN.pow(18))).times(BLOCKS_PER_YEAR)
    // const totalStakingTokenInPool = new BigNumber(priceEth).times(totalStakedPoolAmount)
    // const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);
    // console.log('apr', apr.toNumber());

    
    // let apy:any = (1 + 100/8) * 8 - 1;
    let apy:any = poolApr ? formatNumberAmount(poolApr) : 0;

    return (
        <>
            <div className="main-wrap-section res cus">
                <div className="img-banner-l res">
                    <img src="./images/ban-earn-1.png" alt="" />
                </div>
                <div className="img-banner-r res">
                    <img src="./images/ban-earn-2.png" alt="" />
                </div>
                <div className="container">
                    <div className="content-earn">
                        <div className="title-earn">
                            <div className="img-round-1">
                                <img src="./images/img-round-1.png" alt="" />
                            </div>
                            Staking HOLD-ETH LP to Earn <span className="main-color">RICH</span>
                            <div className="img-round-2">
                                <img src="./images/img-round-2.png" alt="" />
                            </div>
                        </div>
                        <div className="box-info-earn">
                            <div className="title-value">
                                HOLD / ETH
                            </div>
                            <div className="columns">
                                <div className="colum w-3">
                                    <div className="guide-earn">
                                        <div className="txt-top">
                                            TVL
                                        </div>
                                        <div className="txt-bottom">
                                            ${Number(amountEthPrice + amountHoldPrice).toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="guide-earn">
                                        <div className="txt-top">
                                            APY
                                        </div>
                                        <div className="txt-bottom">
                                            {totalStakedPoolAmount > 0 && apy > 0 ? apy + ' %' : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="guide-earn">
                                        <div className="txt-top">
                                            Remaining Rewards
                                        </div>
                                        <div className="txt-bottom">
                                            {Number(pendingRewards).toLocaleString()} RICH
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="guide-earn">
                                        <div className="txt-top">
                                            Issued Rewards
                                        </div>
                                        <div className="txt-bottom">
                                            {Number(issuedRewards).toLocaleString()} RICH
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-staking-reward">
                            <div className="columns">
                                <div className="colum w-6">
                                    <div className="content-staking">
                                        <ContentStaking ethBalance={ethBalance} holdBalance={holdBalance} liquidity={liquidity} priceHold={priceHold} priceEth={priceEth} />
                                    </div>
                                </div>
                                <div className="colum w-6">
                                    <div className="content-staking">
                                        <ContentReward />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Earn