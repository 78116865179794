import React, { useEffect , useState} from "react";
import { useActiveWeb3React } from '../../hooks';
import { Modal , Input , message , Spin} from 'antd';
import { useContract} from "../../hooks/useContract";
import { _widthraw } from "../utils"
import stakeNftLpAbi from "../abi/stakeNftLpAbi.json"
import abiNft from "../abi/abiNft.json"
import ConnectWallet from "../../component/connect-wallet/ConnectWallet";

const ContentReward = () => {
    const { account } = useActiveWeb3React();
    const [amountHold, setAmountHold] = useState<any>();

    const scStake = "0xb662CB95bb91337c497F8bf5c392D1b5a433fFF2"
    const scStakeContract: any = useContract(scStake, stakeNftLpAbi);
    const [isLoading, setIsLoading] = useState(false);
    const [balanceNftId, seBbalanceNftId] = useState(0);

    const nftContract: any = useContract("0xc36442b4a4522e871399cd717abdd847ab11fe88", abiNft);

    const getBlanceNft = async () => {
        if (nftContract) {
            const amount = await nftContract.tokenOfOwnerByIndex(account , 0);
            seBbalanceNftId(amount.toString());
        }
    };

    useEffect(() => {
        getBlanceNft()
    }, [nftContract , account]);

    useEffect(() => {
        if (scStakeContract && account ) {
            scStakeContract.pendingRewardByToken(account , "0x39eC0A79b259b6d72B1D84cCD725Cd59b9d14279").then((res: any) => {
                let amountRes = res.toString() / 1e18
                setAmountHold(amountRes)
            })
        }
    }, [ account , scStakeContract]);

    const handleClaim = async () => {
        setIsLoading(true);
        try {
          await _widthraw(scStakeContract , false).then((res:any) => {
            if (res.hash) {
                message.success("Claim successfully!!! ");
                setIsLoading(false);
            } else {
              setIsLoading(false);
              message.error(res.error.data.message);
            }
          });
        } catch (error:any) {
            console.log("error" , error)
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="guide-row">
                <div className="title">
                    My Reward
                </div>
                <div className="box-img">
                    <img src="./images/rich.png" alt="" />
                </div>
                <div className="reward-row">
                {account ? 
                    <>
                        {amountHold > 0 ? (Number((amountHold).toFixed(2))).toLocaleString() : 0} RICH
                    </>
                    :
                    <>
                        N/A
                    </>
                }
                </div>
                {account ?
                    <>
                        <div className="button-row">
                            {amountHold > 0 ?
                                <>
                                    {isLoading ?
                                        <>
                                            <button className="btn-claim-rw dis" type="button">
                                                Claim <Spin />
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button className="btn-claim-rw" type="button" onClick={handleClaim}>
                                                Claim
                                            </button>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <button className="btn-claim-rw dis" type="button">
                                        Claim
                                    </button>
                                </>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className="gr-connect-earn">
                            <ConnectWallet />
                        </div>
                    </>
                }
                <div className="get-lp">
                    <a target="_blank" href="https://app.uniswap.org/#/swap?outputCurrency=0x39eC0A79b259b6d72B1D84cCD725Cd59b9d14279">Trade on Uniswap <img src="./images/lp.svg" alt="" /></a>
                </div>
            </div>
        </>
    )
}
export default ContentReward